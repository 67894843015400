import {useThree} from "@react-three/fiber";
import {useCallback, useEffect, useState} from "react";

export const CameraAdjuster = () => {
    const { camera, gl } = useThree();
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [initialSizeSet, setInitialSizeSet] = useState(false);

    const updateCameraFOV = useCallback(() => {
        if (size.width > 0 && size.height > 0) {
            const aspect = size.width / size.height;
            const baseFOV = 35;
            console.debug({newFov: 2 * Math.atan(Math.tan((Math.PI / 180) * baseFOV / 2) / aspect) * (180 / Math.PI)})
            camera.fov = 2 * Math.atan(Math.tan((Math.PI / 180) * baseFOV / 2) / aspect) * (180 / Math.PI);
            camera.updateProjectionMatrix();
        }
    }, [camera, size.width, size.height]);

    useEffect(() => {
        const handleResize = () => {
            const newWidth = gl.domElement.clientWidth;
            const newHeight = gl.domElement.clientHeight;
            setSize({ width: newWidth, height: newHeight });
            if (!initialSizeSet && newWidth > 0 && newHeight > 0) {
                setInitialSizeSet(true);
            }
        };

        // Delay initial call using requestAnimationFrame
        requestAnimationFrame(handleResize);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [gl, initialSizeSet]);

    useEffect(() => {
        if (initialSizeSet) {
            updateCameraFOV();
        }
    }, [updateCameraFOV, initialSizeSet]);

    return null
}