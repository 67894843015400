import {forwardRef, Suspense, useImperativeHandle, useRef} from 'react';
import {Canvas as ModelViewerCanvas} from '@react-three/fiber';
import {createUseStyles} from 'react-jss';
import {NoToneMapping} from 'three';
import {CameraControls} from './CameraControls';
import {Hotspots} from '../Hotspot/Hotspots';
import {LoadingAnimation} from '../components/LoadingAnimation';
import {useModelViewerProps, useModelViewerState,} from '../App/ModelViewerContext';
import {ModelOnStage} from "../Model/ModelOnStage";
import {useTheme} from "@mui/material";
import {CameraAdjuster} from "./CameraAdjuster";

const useStyles = createUseStyles({
    canvasContainer: {
        width: '100%',
        height: '100%',
    },
    canvas: {
        width: '100%',
        height: '100%',
    },
});

export const Canvas = forwardRef((props, ref) => {
    const rotationContainerRef = useRef();
    const {data} = useModelViewerProps();
    const {
        showGizmo,
        enableZoom,
        enablePan
    } = useModelViewerState();
    const theme = useTheme()
    const {model} = data;
    const classes = useStyles();

    useImperativeHandle(ref, () => ({
        getRotationContainer() {
            return rotationContainerRef.current;
        }
    }));


    return (
        <div className={classes.canvasContainer}>
            <Suspense fallback={<LoadingAnimation/>}>
                <ModelViewerCanvas
                    camera={{
                        far: 10,
                        near: 0.01,
                        fov: 35,
                    }}
                    gl={{
                        toneMapping: NoToneMapping,
                        preserveDrawingBuffer: true
                    }}
                    className={classes.canvas}
                >
                    <color
                        attach="background"
                        args={[data.model.transparentBackground ? 'rgba(211,211,211, 0)': theme.palette.grey[300]]}
                    />
                    <group
                        ref={rotationContainerRef}
                        name='rotationContainer'
                        rotation={model.rotation}
                    >
                        <ModelOnStage />
                        <Hotspots />
                    </group>
                    <CameraControls
                        showGizmo={showGizmo}
                        enablePan={enablePan}
                        enableZoom={enableZoom}
                        model={model}
                        rotationContainer={rotationContainerRef?.current}
                    />
                    <CameraAdjuster />
                </ModelViewerCanvas>
            </Suspense>
        </div>
    );
});
