import { useState } from 'react';
import { useParams } from 'react-router';
import { App } from '../App/App';
import {demo1} from "./demo01";
import {demo2} from "./demo02";
import {demo3} from "./demo03";
import {demo4} from "./demo04";
import {demo5} from "./demo05";
import {demo6} from "./demo06";
import {demo7} from "./demo07";
import {demo8} from "./demo08";
import {demo9} from "./demo09";
import {demo10} from "./demo10";
import {demo11} from "./demo11";
import {demo12} from "./demo12";
import {demo13} from "./demo13";
import {demo14} from "./demo14";
import {demo15} from "./demo15";
import {demo16} from "./demo16";
import {demo17} from "./demo17";
import {demo18} from "./demo18";
import {demo19} from "./demo19";
import {demo20} from "./demo20";
import {demo21} from "./demo21";
import {demo22} from "./demo22";
import {demo23} from "./demo23";
import {demo24} from "./demo24";
import {demo25} from "./demo25";
import {demo26} from "./demo26";

export const DemoModelViewer = () => {
  const { id } = useParams();
  const [ groupIndex, setGroupIndex ] = useState(id);

  return <App
    adminMode={false}
    data={models[groupIndex]}
    onGroupItemChange={(index) => setGroupIndex(index + 1)}
    onClose={() => console.log('closed')}
  />;
};

const models = {
  1: demo1,
  2: demo2,
  3: demo3,
  4: demo4,
  5: demo5,
  6: demo6,
  7: demo7,
  8: demo8,
  9: demo9,
  10: demo10,
  11: demo11,
  12: demo12,
  13: demo13,
  14: demo14,
  15: demo15,
  16: demo16,
  17: demo17,
  18: demo18,
  19: demo19,
  20: demo20,
  21: demo21,
  22: demo22,
  23: demo23,
  24: demo24,
  25: demo25,
  26: demo26
};
